<template>
	<DialogBase
		:dialogWidth="900"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>Send Message</template>

		<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
			<FormMaker :schema="form_structure" :model="form_data"></FormMaker>
		</v-form>
		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				Send Message Now
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { required, minLen } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null }
		},
		data() {
			return {
				isValidForm: false,
				form_data: {
					country_code: "+1",
					phone_number: null,
					media_url: null,
					body: null
				}
			};
		},
		mounted() {},
		computed: {
			form_structure() {
				let structure = {
					country_code: {
						type: "text",
						name: "country_code",
						label: "Country Code",
						...this.$helpers.formFieldsBasicLayout,
						disabled: true,
						col: { cols: 12, sm: 12, md: 4, lg: 4, xl: 4 },
						rules: []
					},
					phone_number: {
						type: "text",
						name: "phone_number",
						label: "Phone Number",
						...this.$helpers.formFieldsBasicLayout,
						mask: "(###) ###-####",
						col: { cols: 12, sm: 12, md: 8, lg: 8, xl: 8 },
						rules: [required("Phone Number."), minLen(12)]
					},
					media_url: {
						type: "text",
						name: "media_url",
						label: "Media Url",
						...this.$helpers.formFieldsBasicLayout,
						rules: []
					},
					body: {
						type: "textarea",
						name: "body",
						label: "Message body",
						...this.$helpers.formFieldsBasicLayout,
						rows: 3,
						rules: [required("SMS body Is required.")]
					}
				};

				return structure;
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					user_id: this.currentUser?.id,
					...this.form_data
				};

				return await this.post(
					this.baseUrl + `/services/sms/send-message`,
					data,
					true
				).then(data => {
					if (data) {
						this.showMessageFromResponseDta(data);
						if (data.code == 200) {
							this.form_data.to = null;
							this.form_data.body = null;
							this.$emit("created", data.data.config);
						}
					}
				});
			}
		}
	};
</script>
